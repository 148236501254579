/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import renderPara from '../../lib/renderPara';
// import { getNewsSlug } from '../../lib/utils';

const typeToColor = {
  'type-1': 'orange',
  'type-2': 'teal',
  'type-3': 'yellow',
  'type-4': 'pink',
  'type-5': 'orange',
}


export const NewsCardFeatured = props => {

  const latestNews = props.cat;
  if(!latestNews) {
    return null;
  }

  const className = 'type-5';
  const actionText = 'Start Universal Application';
  const actionLink =  'eligibility-form';
  const actionProps = {
    as: actionLink ? 'a' : Button,
    ...(actionLink ? { href: actionLink } : {}),
  };

  return (
    <div className={cx(props.className, className)}>
      <div className="header">
        <div className="tags">
          {_.castArray(_.get(latestNews, 'data.tags', [])).map(tag => {
            return (<div className="tag text-small" key={tag}>{tag}</div>)
          })}
        </div>

        <div className="header-title">
          {props.title}
        </div>
      </div>
      <div className="content">
        <div className="description text-p">
          {renderPara(_.get(latestNews, 'data.description'))}
        </div>
        <Button
          className="button text-p"
          {...(_.get(typeToColor, className) && { color: _.get(typeToColor, className) }) }
          {...actionProps}
        >{actionText}</Button>

      </div>
    </div>
  );
};

export default styled(NewsCardFeatured)`
  position: relative;
  margin: 35px 10px 20px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 20px 15px !important;
  }
  .header {
    padding: 20px 30px;
    border-radius: 8px 8px 0 0;
    border: 1px solid grey;
    border-bottom: none;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 20px 15px !important;
    }
    .header-title {
      font-size: 30px;
      font-weight: bold;
      line-height: 39px;
    }
  }
  .content {
    padding: 10px 30px;
    border-radius: 0 0 8px 8px;
    border: 1px solid grey;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 10px 15px !important;
    }
    .header-title {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .description {
    color: inherit;
    font-size: 16px;
    line-height: 24px;
  }
  .title {
    color: inherit;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .detailLink {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    a {
      font-weight: 700;
      color: ${props => props.theme.txtTeal};
      text-decoration: underline;
    }
  }
  .tags {
    .tag {
      display: inline-block;
      color: ${props => props.theme.primaryWhite};
      text-decoration: underline;
      &:not(:first-child) {
        &:before {
          content: '|';
          margin: 0 2px;
          color: ${props => props.theme.primaryWhite};
          text-decoration: none;
        }
      }
    }
  }
  .button {
    color: inherit;
    padding: 2px 20px;
    font-weight: 300;
  }
  ${props => [
    { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
    { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
    { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
    { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
  ].map(({ name, color, bg }) => `
    &.${name} {
      background: ${bg} !important;
      color: ${color};
      .button {
        border: 0.5px solid ${color};
      }
    }
  `).join('\n')}
  &.type-5 {
    .header {
      background: ${props => props.theme.bgTeal} !important;
      color: ${props => props.theme.primaryWhite};
      .header-title {
      }
    }  
  }
`;
